.body{
    background-color: #f2f2f2;
/*      height: 100%; */ 
    width: 100%;
    grid-area: body;
    /* position: fixed; */
    display: flex;
    flex-direction: column;
    z-index: 0;
    box-shadow: 0 0 2em rgba(66, 66, 66, 0.5);
    /* padding-top: 30px; */
    /* padding-left: 30px;
    padding-right: 30px; */
}

.body-content{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    padding: 0px 30px 50px 30px;
}

.body-content>label{
    text-align:left;
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    width:90%;
    border-bottom: rgb(112, 112, 112) 3px solid;
}

.grupa-body{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 20px;
    padding: 10px; 
}

.body-content a{
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}


.block{
    border: rgb(112, 112, 112) 3px solid;;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px;
    padding: 10px; 
}
div {
    opacity: 1;
    animation: fade 0.5s linear;
}
.animateup
{
    opacity: 0;
    animation: up 0.5s linear;
}
.animatedown
{
    opacity: 0;
    animation: down 0.5s linear;
}

@keyframes fade {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes up {
    0% { opacity: 0; transform: translateY(60px) }
    100% { opacity: 1; transform: translateY(0) }
    
}
@keyframes down {
    0% { opacity: 0; transform: translateY(-60px) }
    100% { opacity: 1; transform: translateY(0) }
    
}