body{
    background-color: #f2f2f2;
   
}
.navbar{
    grid-area: header;
    background-color: #f2f2f2;
    /* height: 75px;
    width: 100%; */
    /* position:fixed; */
    display: flex;
    align-items: center;
    z-index: 2;
    box-shadow: 0 0 2em rgba(66, 66, 66, 0.5);
}
.logo{
    display: flex;
    justify-content: left;
    margin: 10px;
    width: 230px;
    height: auto;
}
.navbar-links{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-right: 10px;
    height: 50px;
    /* padding-left: 20px; */
}
.navbar-links a{
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}