body{
    background-color: #f2f2f2;

}
.aside{
    background-color: #f2f2f2;
    grid-area: aside;
    /* height: 100%; */
    /* width: 250px; */
    /* margin-top: 75px; */
    position: sticky;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 1;
    box-shadow: 0 0 2em rgba(66, 66, 66, 0.5);
}

.aside-links{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    margin-right: 10px;
}
.aside-links a{
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}
.aside-links li{
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}
.aside-links div{
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}
.aside-links li:hover{
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
    cursor: pointer;
}
.aside-links li:hover a{
    color: rgb(29, 29, 29);
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
    cursor: pointer;
}

.grupa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5px;
    /* padding-left: 10px;  */
}

#samochod{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    /* padding-left: 30px;  */
}
.MuiDrawer-paper {
    width: 20% !important;
    min-width: 170px !important;
}